import { get, isFunction, isObject, result } from "lodash";
export class Model {
  constructor(attributes) {
    this.#assignAttributes(result(this, "defaults", {}));
    this.#assignAttributes(attributes);
    this.#defineGetters();
  }

  get defaults() {
    return {};
  }

  #assignAttributes(attributes) {
    const descriptors = this.#descriptors;

    for (const key in attributes) {
      if (!(key in descriptors) || descriptors[key].writable) {
        this[key] = attributes[key];
      }
    }
  }

  #defineGetters() {
    const ownPropertyNames = [...Object.getOwnPropertyNames(this), "constructor", "defaults"];

    for (const [key, descriptor] of Object.entries(this.#descriptors)) {
      if (!ownPropertyNames.includes(key)) {
        Reflect.defineProperty(this, key, { ...descriptor, enumerable: true });
      }
    }
  }

  get #descriptors() {
    return Object.getOwnPropertyDescriptors(Object.getPrototypeOf(this));
  }

  updateAttributes(attributes) {
    for (const [key, value] of Object.entries(attributes)) {
      if (Reflect.getOwnPropertyDescriptor(this, key)?.writable) {
        this[key] = value;
      }
    }
    return this;
  }

  hashCode() {
    const compoundKey = this.hashCompoundKey();
    let hash = 0;
    for (let i = 0; i < compoundKey.length; i++) {
      hash = compoundKey.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  wouldUpdateWith(attributes) {
    for (const [key, value] of Object.entries(attributes)) {
      if (this[key] !== value) {
        return true;
      }
    }
    return false;
  }

  hashCompoundKey() {
    return Object.values(this.toJSON()).join("");
  }

  toJSON({ onlyDefaults = false, onlyDefined = false } = {}) {
    const reducer = (acc, key) => {
      const value = this[key];

      if (onlyDefined && value === undefined) {
        return acc;
      }
      if (!isFunction(value) && !(!isObject(value) && isFunction(get(value, "toJSON")))) {
        acc[key] = result(value, "toJSON", value);
      }
      return acc;
    };

    return Object.keys(this)
      .filter((key) => !onlyDefaults || key in this.defaults)
      .reduce(reducer, {});
  }
}
