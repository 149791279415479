import axios from "axios";
import { Model } from "../models/model";

export class BaseApi {
  #api;
  #baseURL;
  #httpClient;
  #model;
  #config;
  #root;

  constructor({ api, baseURL, model, httpClient, config, root }) {
    this.#api = api;
    this.#baseURL = baseURL ?? "";
    this.#httpClient = httpClient ?? axios;
    this.#model = model ?? Model;
    this.#config = config ?? {};
    this.#root = root;
    this.#enforceAcceptHeader();
  }

  #enforceAcceptHeader() {
    this.#httpClient.defaults.headers.common.Accept = "application/json";
  }

  parse(data) {
    return new this.#model(data);
  }

  transform(data) {
    if (this.#root) {
      data = data?.[this.#root];
    }
    if (!data) {
      return null;
    }
    if (Array.isArray(data)) {
      return data.map((datum) => this.parse(datum));
    }
    return this.parse(data);
  }

  async get(path, query = {}) {
    const params = this.params;

    for (const [key, value] of Object.entries(query)) {
      params.append(key, value);
    }

    const options = {
      ...this.config,
      params,
    };

    const { data } = await this.#httpClient.get(path, options);
    return this.transform(data);
  }

  async post(path, data) {
    const options = {
      ...this.config,
      params: this.params,
    };

    const response = await this.#httpClient.post(path, data, options);
    return this.transform(response.data);
  }

  async patch(path, data) {
    const options = {
      ...this.config,
      params: this.params,
    };

    const response = await this.#httpClient.patch(path, data, options);
    return this.transform(response.data);
  }

  async delete(path) {
    const options = {
      ...this.config,
      params: this.params,
    };

    const response = await this.#httpClient.delete(path, options);

    if (response.status === 204) {
      return null;
    }

    return this.transform(response.data);
  }

  get params() {
    const params = new URLSearchParams();
    if (this.#api) {
      params.append("api", this.#api);
    }

    return params;
  }

  get config() {
    const isFullyQualifiedURL = this.#baseURL?.startsWith("http");

    return {
      ...this.#config,
      baseURL:
        !isFullyQualifiedURL && axios.defaults.embedHost
          ? `${axios.defaults.embedHost}${this.#baseURL}`
          : this.#baseURL,
    };
  }
}
